import { Button } from "react-bootstrap";
import styled from "styled-components";

export const SubmitButton = styled(Button)`
      display: flex;
      align-items: center;
      justify-content: center;
      width: ${(props) => props.width || "120px"};

      .spinner-border {
            margin: 0;
            width: 20px;
            height: 20px;
            border-width: 2px;
            color: #66615b;
      }
`;
