import React from "react";
import { MenuSeparator } from "../../navigation/styles";
import MenuItem from "../../metis/MenuItem";
import CatalogueIcon from "../../dashboard/CatalogueIcon";
import BlogIcon from "../../dashboard/BlogIcon";
import CheckMultiplePermission from "../../roles/CheckMultiplePermission";
import MenuLink from "../../metis/MenuLink";

const ContentManage = ({ userPermissions }) => {
      return (
            <>
                  <MenuSeparator>Content Manage</MenuSeparator>
                  <CheckMultiplePermission
                        userPermissions={userPermissions}
                        permissions={[
                              "catalogue-list",
                              "catalogue-create",
                              "catalogue-edit",
                              "catalogue-show",
                              "blog-list",
                              "blog-edit",
                              "blog-create",
                              "blog-show",
                              "blog-category-list",
                              "blog-category-create",
                              "blog-category-show",
                              "blog-category-edit",
                              "faq-index",
                              "faq-edit",
                              "faq-create",
                              "faq-show",
                              "faq-type-index",
                              "faq-type-create",
                              "faq-type-show",
                              "faq-type-edit",
                              "faq-request-list",
                              "faq-request-show",
                              "page-list",
                              "page-edit",
                              "page-show",
                              "page-create",
                        ]}
                  >
                        <CheckMultiplePermission
                              userPermissions={userPermissions}
                              permissions={[
                                    "catalogue-list",
                                    "catalogue-edit",
                                    "catalogue-create",
                                    "catalogue-show",
                              ]}
                        >
                              <MenuItem
                                    title="Catalogue Manage"
                                    icon={<CatalogueIcon />}
                                    links={[
                                          "/dashboard/catalogues",
                                          "/dashboard/catalogues/create",
                                          "/dashboard/catalogues/:catalogueId/edit",
                                          "/dashboard/catalogues/:catalogueId/show",
                                    ]}
                              >
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                "catalogue-list",
                                                "catalogue-edit",
                                                "catalogue-create",
                                                "catalogue-show",
                                          ]}
                                    >
                                          <MenuLink
                                                title="Catalogue List"
                                                link="/dashboard/catalogues"
                                                links={[
                                                      "/dashboard/catalogues",
                                                      "/dashboard/catalogues/create",
                                                      "/dashboard/catalogues/:catalogueId/edit",
                                                      "/dashboard/catalogues/:catalogueId/show",
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                              </MenuItem>
                        </CheckMultiplePermission>
                        <CheckMultiplePermission
                              userPermissions={userPermissions}
                              permissions={[
                                    "blog-list",
                                    "blog-edit",
                                    "blog-create",
                                    "blog-show",
                                    "blog-category-list",
                                    "blog-category-create",
                                    "blog-category-show",
                                    "blog-category-edit",
                              ]}
                        >
                              <MenuItem
                                    title="Blog Manage"
                                    icon={<BlogIcon />}
                                    links={[
                                          "/dashboard/blogs",
                                          "/dashboard/blogs/create",
                                          "/dashboard/blogs/:blogId/edit",
                                          "/dashboard/blogs/:blogId/show",
                                          "/dashboard/blogs/categories/list",
                                          "/dashboard/blogs/categories/create",
                                          "/dashboard/blogs/categories/:categoryId/edit",
                                          "/dashboard/blogs/categories/:categoryId/show",
                                    ]}
                              >
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                "blog-list",
                                                "blog-edit",
                                                "blog-create",
                                                "blog-show",
                                          ]}
                                    >
                                          <MenuLink
                                                title="Blog Posts"
                                                link="/dashboard/blogs"
                                                links={[
                                                      "/dashboard/blogs",
                                                      "/dashboard/blogs/create",
                                                      "/dashboard/blogs/:blogId/edit",
                                                      "/dashboard/blogs/:blogId/show",
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                "blog-category-list",
                                                "blog-category-create",
                                                "blog-category-show",
                                                "blog-category-edit",
                                          ]}
                                    >
                                          <MenuLink
                                                title="Blog Category"
                                                link="/dashboard/blogs/categories/list"
                                                links={[
                                                      "/dashboard/blogs/categories/list",
                                                      "/dashboard/blogs/categories/create",
                                                      "/dashboard/blogs/categories/:categoryId/edit",
                                                      "/dashboard/blogs/categories/:categoryId/show",
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                              </MenuItem>
                        </CheckMultiplePermission>

                        <CheckMultiplePermission
                              userPermissions={userPermissions}
                              permissions={[
                                    "faq-index",
                                    "faq-edit",
                                    "faq-create",
                                    "faq-show",
                                    "faq-type-index",
                                    "faq-type-create",
                                    "faq-type-show",
                                    "faq-type-edit",
                                    "faq-request-list",
                                    "faq-request-show",
                              ]}
                        >
                              <MenuItem
                                    title="Faq Manage"
                                    icon={<BlogIcon />}
                                    links={[
                                          "/dashboard/faqs",
                                          "/dashboard/faqs/create",
                                          "/dashboard/faqs/:faqId/edit",
                                          "/dashboard/faqs/:faqId/show",
                                          "/dashboard/faqs/types",
                                          "/dashboard/faqs/types/create",
                                          "/dashboard/faqs/types/:typeId/edit",
                                          "/dashboard/faqs/types/:typeId/show",
                                          "/dashboard/faqs/requests",
                                          "/dashboard/faqs/requests/:faqId/edit",
                                          "/dashboard/faqs/requests/:faqId/show",
                                    ]}
                              >
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                "faq-index",
                                                "faq-edit",
                                                "faq-create",
                                                "faq-show",
                                          ]}
                                    >
                                          <MenuLink
                                                link="/dashboard/faqs"
                                                title="All Faqs"
                                                links={[
                                                      "/dashboard/faqs",
                                                      "/dashboard/faqs/create",
                                                      "/dashboard/faqs/:faqId/edit",
                                                      "/dashboard/faqs/:faqId/show",
                                                ]}
                                          />
                                    </CheckMultiplePermission>

                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                "faq-type-index",
                                                "faq-type-create",
                                                "faq-type-show",
                                                "faq-type-edit",
                                          ]}
                                    >
                                          <MenuLink
                                                link="/dashboard/faqs/types"
                                                title="Faq Type"
                                                links={[
                                                      "/dashboard/faqs/types",
                                                      "/dashboard/faqs/types/create",
                                                      "/dashboard/faqs/types/:typeId/edit",
                                                      "/dashboard/faqs/types/:typeId/show",
                                                ]}
                                          />
                                    </CheckMultiplePermission>

                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                "faq-request-list",
                                                "faq-request-show",
                                          ]}
                                    >
                                          <MenuLink
                                                link="/dashboard/faqs/requests"
                                                title="Faq Request List"
                                                links={[
                                                      "/dashboard/faqs/requests",
                                                      "/dashboard/faqs/requests/:faqId/edit",
                                                      "/dashboard/faqs/requests/:faqId/show",
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                              </MenuItem>
                        </CheckMultiplePermission>

                        <CheckMultiplePermission
                              userPermissions={userPermissions}
                              permissions={[
                                    "page-list",
                                    "page-edit",
                                    "page-show",
                                    "page-create",
                              ]}
                        >
                              <MenuItem
                                    title="Page Manage"
                                    icon={<BlogIcon />}
                                    links={[
                                          "/dashboard/pages",
                                          "/dashboard/pages/create",
                                          "/dashboard/pages/:pageId/edit",
                                          "/dashboard/pages/:pageId/show",
                                    ]}
                              >
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                "page-list",
                                                "page-edit",
                                                "page-show",
                                                "page-create",
                                          ]}
                                    >
                                          <MenuLink
                                                title="Page List"
                                                link="/dashboard/pages"
                                                links={[
                                                      "/dashboard/pages",
                                                      "/dashboard/pages/create",
                                                      "/dashboard/pages/:pageId/edit",
                                                      "/dashboard/pages/:pageId/show",
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                              </MenuItem>
                        </CheckMultiplePermission>
                  </CheckMultiplePermission>
            </>
      );
};

export default ContentManage;
