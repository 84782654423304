export const FileUpload = async (file) => {
      let result = null;

      let formData = new FormData();
      formData.append("image", file);

      await fetch(
            `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/images/upload`,
            {
                  method: "POST",
                  headers: {
                        Authorization: `Bearer ${JSON.parse(
                              localStorage.getItem("token")
                        )}`,
                  },
                  body: formData,
            }
      )
            .then((response) => response.json())
            .then((data) => {
                  result = data;
            })
            .catch((error) => {
                  console.error("Error:", error);
            });

      return result;
};
