import React from "react";
import CheckMultiplePermission from "../../roles/CheckMultiplePermission";
import { MenuSeparator } from "../../navigation/styles";
import MenuItem from "../../metis/MenuItem";
import MenuLink from "../../metis/MenuLink";
import { ProductIcon } from "../../dashboard";

const ProductManage = ({ userPermissions }) => {
      return (
            <>
                  <CheckMultiplePermission
                        userPermissions={userPermissions}
                        permissions={[
                              "product-list",
                              "product-create",
                              "product-edit",
                              "product-show",
                              "category-list",
                              "category-create",
                              "category-edit",
                              "category-show",
                              "brand-list",
                              "brand-edit",
                              "brand-create",
                              "brand-delete",
                              "product-enquiry-list",
                              "product-enquiry-create",
                              "product-enquiry-edit",
                              "product-enquiry-delete",
                              "service-list",
                              "service-edit",
                              "service-create",
                              "service-delete",
                              "package-list",
                              "package-edit",
                              "package-create",
                              "package-delete",
                              "package-enquiry-list",
                              "package-enquiry-delete",
                        ]}
                  >
                        <MenuSeparator>Ecommerce Manage</MenuSeparator>
                        <CheckMultiplePermission
                              userPermissions={userPermissions}
                              permissions={[
                                    "brand-request-list",
                                    "brand-request-create",
                                    "brand-request-edit",
                                    "brand-request-show",
                                    "brand-list",
                                    "brand-edit",
                                    "brand-create",
                                    "brand-delete",
                              ]}
                        >
                              <MenuItem
                                    title="Brand Mange"
                                    icon={<ProductIcon />}
                                    links={[
                                          "/dashboard/brand_requests",
                                          "/dashboard/brand_requests/create",
                                          "/dashboard/brand_requests/:brandId/edit",
                                          "/dashboard/brand_requests/:brandId/show",
                                          "/dashboard/brands",
                                          "/dashboard/brands/create",
                                          "/dashboard/brands/:brandId/edit",
                                          "/dashboard/brands/:brandId/show",
                                    ]}
                              >
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                "brand-list",
                                                "brand-create",
                                                "brand-edit",
                                                "brand-show",
                                          ]}
                                    >
                                          <MenuLink
                                                title="Brand List"
                                                link="/dashboard/brands"
                                                links={[
                                                      "/dashboard/brands",
                                                      "/dashboard/brands/create",
                                                      "/dashboard/brands/:brandId/edit",
                                                      "/dashboard/brands/:brandId/show",
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                "brand-request-list",
                                                "brand-request-create",
                                                "brand-request-edit",
                                                "brand-request-show",
                                          ]}
                                    >
                                          <MenuLink
                                                title="Brand Request"
                                                link="/dashboard/brand_requests"
                                                links={[
                                                      "/dashboard/brand_requests",
                                                      "/dashboard/brand_requests/create",
                                                      "/dashboard/brand_requests/:brandId/edit",
                                                      "/dashboard/brand_requests/:brandId/show",
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                              </MenuItem>
                        </CheckMultiplePermission>
                        <CheckMultiplePermission
                              userPermissions={userPermissions}
                              permissions={[
                                    "product-list",
                                    "product-create",
                                    "product-edit",
                                    "product-show",
                                    "category-list",
                                    "category-create",
                                    "category-edit",
                                    "category-show",
                                    "product-enquiry-list",
                                    "product-enquiry-delete",
                              ]}
                        >
                              <MenuItem
                                    title="Product Manage"
                                    icon={<ProductIcon />}
                                    links={[
                                          "/dashboard/products",
                                          "/dashboard/products/create",
                                          "/dashboard/products/:productId/edit",
                                          "/dashboard/products/:productId/show",
                                          "/dashboard/categories",
                                          "/dashboard/categories/create",
                                          "/dashboard/categories/:categoryId/edit",
                                          "/dashboard/categories/:categoryId/show",
                                          "/dashboard/product_enquiries",
                                          "/dashboard/product_enquiries/:enquiryId/show",
                                    ]}
                              >
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                "category-list",
                                                "category-create",
                                                "category-edit",
                                                "category-show",
                                          ]}
                                    >
                                          <MenuLink
                                                title="Category"
                                                link="/dashboard/categories"
                                                links={[
                                                      "/dashboard/categories",
                                                      "/dashboard/categories/create",
                                                      "/dashboard/categories/:categoryId/edit",
                                                      "/dashboard/categories/:categoryId/show",
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                "product-list",
                                                "product-create",
                                                "product-edit",
                                                "product-show",
                                          ]}
                                    >
                                          <MenuLink
                                                title="Product List"
                                                link="/dashboard/products"
                                                links={[
                                                      "/dashboard/products",
                                                      "/dashboard/products/create",
                                                      "/dashboard/products/:productId/edit",
                                                      "/dashboard/products/:productId/show",
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                "product-enquiry-list",
                                                "product-enquiry-delete",
                                          ]}
                                    >
                                          <MenuLink
                                                title="Product Enquiry"
                                                link="/dashboard/product_enquiries"
                                                links={[
                                                      "/dashboard/product_enquiries",
                                                      "/dashboard/product_enquiries/:enquiryId/show",
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                              </MenuItem>
                        </CheckMultiplePermission>
                        <CheckMultiplePermission
                              userPermissions={userPermissions}
                              permissions={[
                                    "package-list",
                                    "package-create",
                                    "package-edit",
                                    "package-delete",
                                    "package-enquiry-list",
                                    "package-enquiry-delete",
                              ]}
                        >
                              <MenuItem
                                    title="Package Manage"
                                    icon={<ProductIcon />}
                                    links={[
                                          "/dashboard/packages",
                                          "/dashboard/packages/create",
                                          "/dashboard/packages/:packageId/edit",
                                          "/dashboard/packages/:packageId/show",
                                          "/dashboard/package_enquiries",
                                          "/dashboard/package_enquiries/:enquiryId/show",
                                    ]}
                              >
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                "package-list",
                                                "package-create",
                                                "package-edit",
                                                "package-delete",
                                          ]}
                                    >
                                          <MenuLink
                                                title="Package List"
                                                link="/dashboard/packages"
                                                links={[
                                                      "/dashboard/packages",
                                                      "/dashboard/packages/create",
                                                      "/dashboard/packages/:packageId/edit",
                                                      "/dashboard/packages/:packageId/show",
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                "package-enquiry-list",
                                                "package-enquiry-delete",
                                          ]}
                                    >
                                          <MenuLink
                                                title="Enquiry List"
                                                link="/dashboard/package_enquiries"
                                                links={[
                                                      "/dashboard/package_enquiries",
                                                      "/dashboard/package_enquiries/:enquiryId/show",
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                              </MenuItem>
                        </CheckMultiplePermission>
                        <CheckMultiplePermission
                              userPermissions={userPermissions}
                              permissions={[
                                    "service-list",
                                    "service-create",
                                    "service-edit",
                                    "service-delete",
                                    "icon-list",
                                    "icon-create",
                                    "icon-edit",
                                    "icon-delete",
                                    "service-enquiry-list",
                                    "service-enquiry-delete",
                              ]}
                        >
                              <MenuItem
                                    title="Service Manage"
                                    icon={<ProductIcon />}
                                    links={[
                                          "/dashboard/services",
                                          "/dashboard/services/create",
                                          "/dashboard/services/:serviceId/edit",
                                          "/dashboard/services/:serviceId/show",
                                          "/dashboard/icons",
                                          "/dashboard/icons/create",
                                          "/dashboard/icons/:serviceId/edit",
                                          "/dashboard/icons/:serviceId/show",
                                          "/dashboard/service_enquiries",
                                          "/dashboard/service_enquiries/:enquiryId/show",
                                    ]}
                              >
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                "icon-list",
                                                "icon-create",
                                                "icon-edit",
                                                "icon-delete",
                                          ]}
                                    >
                                          <MenuLink
                                                title="Icon List"
                                                link="/dashboard/icons"
                                                links={[
                                                      "/dashboard/icons",
                                                      "/dashboard/icons/create",
                                                      "/dashboard/icons/:serviceId/edit",
                                                      "/dashboard/icons/:serviceId/show",
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                "service-list",
                                                "service-create",
                                                "service-edit",
                                                "service-delete",
                                          ]}
                                    >
                                          <MenuLink
                                                title="Service List"
                                                link="/dashboard/services"
                                                links={[
                                                      "/dashboard/services",
                                                      "/dashboard/services/create",
                                                      "/dashboard/services/:serviceId/edit",
                                                      "/dashboard/services/:serviceId/show",
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                "service-enquiry-list",
                                                "service-enquiry-delete",
                                          ]}
                                    >
                                          <MenuLink
                                                title="Enquiry List"
                                                link="/dashboard/service_enquiries"
                                                links={[
                                                      "/dashboard/service_enquiries",
                                                      "/dashboard/service_enquiries/:enquiryId/show",
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                              </MenuItem>
                        </CheckMultiplePermission>
                        <CheckMultiplePermission
                              userPermissions={userPermissions}
                              permissions={[
                                    "waste-enquiry-list",
                                    "waste-enquiry-delete",
                              ]}
                        >
                              <MenuItem
                                    title="Waste Manage"
                                    icon={<ProductIcon />}
                                    links={[
                                          "/dashboard/waste_enquiries",
                                          "/dashboard/waste_enquiries/:enquiryId/show",
                                    ]}
                              >
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                "waste-enquiry-list",
                                                "waste-enquiry-delete",
                                          ]}
                                    >
                                          <MenuLink
                                                title="Enquiry List"
                                                link="/dashboard/waste_enquiries"
                                                links={[
                                                      "/dashboard/waste_enquiries",
                                                      "/dashboard/waste_enquiries/:enquiryId/show",
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                              </MenuItem>
                        </CheckMultiplePermission>
                  </CheckMultiplePermission>
            </>
      );
};

export default ProductManage;
