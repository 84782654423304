import React, { useState, useEffect } from "react";

import axios from "axios";
import { Card, Col, Row } from "react-bootstrap";

import Progress from "react-progress-2";
import { toast } from "react-hot-toast";

import CardHeader from "../../components/common/CardHeader";
import LinkButton from "../../components/common/LinkButton";
import BlogListBox from "../../components/backend/BlogListBox";

const BlogTable = () => {
      const [loading, setLoading] = useState(false);
      const [blogs, setBlogs] = useState([]);
      const [totalBlogs, setTotalBlogs] = useState(0);
      const [perPages, setPerPages] = useState(0);
      const [currentPage, setCurrentPage] = useState(0);

      const token = JSON.parse(localStorage.getItem("token"));
      const [limit, setLimit] = useState(10);
      const [search, setSearch] = useState("");

      const fetchBlogs = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/blogs?limit=${limit}&search=${search}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                              },
                        }
                  )
                  .then((response) => {
                        setBlogs(response.data.blogs);
                        setTotalBlogs(response.data.totalBlogs);
                        setPerPages(response.data.itemsCountPerPage);
                        setCurrentPage(response.data.currentPage);
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            fetchBlogs();
      }, [limit, search]);

      async function getData(pageNumber = 1) {
            window.scrollTo(0, 0);
            setLoading(true);
            Progress.show();
            const url = `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/blogs?page=${pageNumber}&limit=${limit}&search=${search}`;
            await axios(url, {
                  headers: {
                        Authorization: `Bearer ${token}`,
                  },
            })
                  .then((response) => {
                        if (response.data.result === "success") {
                              setBlogs(response.data.blogs);
                              setTotalBlogs(response.data.totalBlogs);
                              setPerPages(response.data.itemsCountPerPage);
                              setCurrentPage(response.data.currentPage);
                        }
                        Progress.hide();
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      }

      return (
            <>
                  <Row>
                        <Col lg={12}>
                              <Card>
                                    <CardHeader title="Blog List">
                                          <LinkButton
                                                link="/dashboard/blogs/create"
                                                title="Add New"
                                          />
                                    </CardHeader>
                                    <BlogListBox
                                          loading={loading}
                                          blogs={blogs}
                                          totalBlogs={totalBlogs}
                                          perPages={perPages}
                                          setLimit={setLimit}
                                          currentPage={currentPage}
                                          getData={getData}
                                          fetchBlogs={fetchBlogs}
                                          setLoading={setLoading}
                                          setSearch={setSearch}
                                          setBlogs={setBlogs}
                                    />
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default BlogTable;
