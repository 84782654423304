import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { Card, Col, Form, Row } from "react-bootstrap";
import { toast } from "react-hot-toast";
import CardHeader from "../../components/common/CardHeader";
import LinkButton from "../../components/common/LinkButton";
import {
      FormButton,
      FormDescription,
      FormImage,
      FormInput,
      FormSeo,
      FormStatusSelect,
      FormTextarea,
} from "../../components/form";
import { set } from "jodit/esm/core/helpers";

const PageEdit = () => {
      const { pageId } = useParams();
      const navigate = useNavigate();

      const [title, setTitle] = useState("");
      const [slug, setSlug] = useState("");
      const [image, setImage] = useState("");
      const [imageUrl, setImageUrl] = useState("");
      const [description, setDescription] = useState("");
      const [status, setStatus] = useState("");
      const [loading, setLoading] = useState(false);

      const [seoTitle, setSeoTitle] = useState(null);
      const [seoKeyword, setSeoKeyword] = useState(null);
      const [seoDescription, setSeoDescription] = useState(null);

      useEffect(() => {
            const loadData = async () => {
                  await axios
                        .get(
                              `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/pages/${pageId}`,
                              {
                                    headers: {
                                          Authorization: `Bearer ${JSON.parse(
                                                localStorage.getItem("token")
                                          )}`,
                                          "Content-Type": "multipart/form-data",
                                    },
                              }
                        )
                        .then((response) => {
                              if (response.data.result === "success") {
                                    setTitle(response.data.page.title);
                                    setDescription(
                                          response.data.page.description
                                    );
                                    setSlug(response.data.page.slug);
                                    setStatus(response.data.page.status);
                                    setSeoTitle(response.data.page.seo_title);
                                    setSeoKeyword(
                                          response.data.page.seo_keyword
                                    );
                                    setSeoDescription(
                                          response.data.page.seo_description
                                    );
                                    setImageUrl(response.data.page.image);
                              }
                        })
                        .catch((error) => {
                              toast.error(error.message);
                        });
            };
            loadData();
      }, []);

      const handleFormSubmit = async (event) => {
            event.preventDefault();
            setLoading(true);
            const data = {
                  title: title,
                  image: image,
                  description: description,
                  slug: slug,
                  status: status,
                  seo_title: seoTitle,
                  seo_keyword: seoKeyword,
                  seo_description: seoDescription,
            };

            let updatedSlug = false;

            const handleUpdateSlug = () => {
                  if (updatedSlug) {
                        navigate(`/dashboard/pages/${updatedSlug}/edit`);
                  }
            };

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/pages/${pageId}`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem("token")
                                    )}`,
                                    "Content-Type": "multipart/form-data",
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              toast.success(response.data.message);
                              updatedSlug = slug;
                              handleUpdateSlug();
                        }
                        if (response.data.result === "error") {
                              if (response.data.message.title) {
                                    toast.error(response.data.message.title);
                              }
                              if (response.data.message.image) {
                                    toast.error(response.data.message.image);
                              }
                              if (response.data.message.job_type) {
                                    toast.error(response.data.message.job_type);
                              }
                              if (response.data.message.education) {
                                    toast.error(response.data.message.education);
                              }
                              if (response.data.message.experience) {
                                    toast.error(response.data.message.experience);
                              }
                              if (response.data.message.no_of_vacancy) {
                                    toast.error(response.data.message.no_of_vacancy);
                              }
                              if (response.data.message.salary) {
                                    toast.error(response.data.message.salary);
                              }
                              if (response.data.message.deadline) {
                                    toast.error(response.data.message.deadline);
                              }
                              if (response.data.message.summary) {
                                    toast.error(response.data.message.summary);
                              }
                              if (response.data.message.description) {
                                    toast.error(
                                          response.data.message.description
                                    );
                              }
                              if (response.data.message.status) {
                                    toast.error(response.data.message.status);
                              }
                              if (response.data.message.slug) {
                                    toast.error(response.data.message.slug);
                              }
                              if (response.data.message.seo_description) {
                                    toast.error(
                                          response.data.message.seo_description
                                    );
                              }
                              if (response.data.message.seo_title) {
                                    toast.error(
                                          response.data.message.seo_title
                                    );
                              }
                              if (response.data.message.seo_keyword) {
                                    toast.error(
                                          response.data.message.seo_keyword
                                    );
                              }
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      return (
            <>
                  <Form onSubmit={handleFormSubmit}>
                        <Row>
                              <Col lg={12}>
                                    <Card>
                                          <CardHeader title="Edit Page">
                                                <LinkButton
                                                      link="/dashboard/pages"
                                                      title="Back"
                                                />
                                          </CardHeader>
                                          <Card.Body>
                                                <Row className="g-3">
                                                      <FormInput
                                                            title="Title"
                                                            required={true}
                                                            classes={8}
                                                            value={title}
                                                            handleChange={
                                                                  setTitle
                                                            }
                                                      />
                                                      <FormInput
                                                            title="Slug"
                                                            required={true}
                                                            classes={4}
                                                            value={slug}
                                                            handleChange={
                                                                  setSlug
                                                            }
                                                      />
                                                      <FormStatusSelect
                                                            title="Status"
                                                            classes={4}
                                                            selected={status}
                                                            setStatus={
                                                                  setStatus
                                                            }
                                                      />
                                                      <FormDescription
                                                            title="Description"
                                                            description={
                                                                  description
                                                            }
                                                            setDescription={
                                                                  setDescription
                                                            }
                                                      />
                                                </Row>
                                          </Card.Body>
                                    </Card>

                                    <Card className="mt-4">
                                          <Card.Body>
                                                <Row>
                                                      <FormImage
                                                            title="Featured Image"
                                                            setImage={setImage}
                                                            setImageUrl={
                                                                  setImageUrl
                                                            }
                                                            imageUrl={imageUrl}
                                                      />
                                                </Row>
                                          </Card.Body>
                                    </Card>
                                    <FormSeo
                                          setSeoDescription={setSeoDescription}
                                          setSeoTitle={setSeoTitle}
                                          setSeoKeyword={setSeoKeyword}
                                          description={seoDescription}
                                          title={seoTitle}
                                          keyword={seoKeyword}
                                    />
                                    <Card className="mt-4 mb-5">
                                          <Card.Body>
                                                <Col lg={12}>
                                                      <FormButton
                                                            title="Submit"
                                                            loading={loading}
                                                      />
                                                </Col>
                                          </Card.Body>
                                    </Card>
                              </Col>
                        </Row>
                  </Form>
            </>
      );
};

export default PageEdit;
