import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import $ from 'jquery';
import { useEffect } from 'react';
import {
      FormButton,
      FormInput,
      FormSelect,
      FormStatusSelect,
      FormTextarea
} from '../../components/form';
import { Card, Col, Form, Row } from 'react-bootstrap';
import CardHeader from '../../components/common/CardHeader';
import LinkButton from '../../components/common/LinkButton';

const FaqCreate = () => {
      const [answer, setAnswer] = useState('');
      const [question, setQuestion] = useState('');
      const [status, setStatus] = useState('');
      const [loading, setLoading] = useState(false);

      const handleFormSubmit = async (event) => {
            event.preventDefault();
            setLoading(true);
            const data = {
                  answer: answer,
                  question: question,
                  status: status
            };
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/faqs`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`,
                                    'Content-Type': 'multipart/form-data'
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              toast.success(response.data.message);
                              $('form').trigger('reset');
                        }
                        if (response.data.result === 'error') {
                              if (response.data.message.question) {
                                    toast.error(response.data.message.question);
                              }
                              if (response.data.message.answer) {
                                    toast.error(response.data.message.answer);
                              }
                              if (response.data.message.status) {
                                    toast.error(response.data.message.status);
                              }
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      return (
            <>
                  <Form onSubmit={handleFormSubmit}>
                        <Row>
                              <Col lg={12}>
                                    <Card>
                                          <CardHeader title="Create Faq">
                                                <LinkButton
                                                      link="/dashboard/faqs"
                                                      title="Back"
                                                />
                                          </CardHeader>
                                          <Card.Body>
                                                <Row className="g-3">
                                                      <FormInput
                                                            title="Question"
                                                            required={true}
                                                            classes={8}
                                                            handleChange={
                                                                  setQuestion
                                                            }
                                                      />
                                                      <FormStatusSelect
                                                            title="Status"
                                                            classes={4}
                                                            setStatus={
                                                                  setStatus
                                                            }
                                                      />
                                                      <FormTextarea
                                                            title="Answer"
                                                            required={true}
                                                            handleChange={
                                                                  setAnswer
                                                            }
                                                      />
                                                </Row>
                                          </Card.Body>
                                    </Card>

                                    <Card className="mt-4 mb-5">
                                          <Card.Body>
                                                <Col lg={12}>
                                                      <FormButton
                                                            title="Submit"
                                                            loading={loading}
                                                      />
                                                </Col>
                                          </Card.Body>
                                    </Card>
                              </Col>
                        </Row>
                  </Form>
            </>
      );
};

export default FaqCreate;
