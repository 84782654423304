import React, { useEffect, useState } from "react";
import axios from "axios";
import $ from "jquery";
import { toast } from "react-hot-toast";

import { Card, Col, Form, Row } from "react-bootstrap";
import CardHeader from "../../components/common/CardHeader";
import LinkButton from "../../components/common/LinkButton";
import FormInput from "../../components/form/FormInput";
import {
      FormButton,
      FormDescription,
      FormImage,
      FormSelect,
      FormSeo,
      FormStatusSelect,
      FormTextarea,
} from "../../components/form";

const BlogCreate = () => {
      const [categories, setCategories] = useState([]);
      const [isLoading, setIsLoading] = useState(false);

      const [title, setTitle] = useState("");
      const [summary, setSummary] = useState("");
      const [image, setImage] = useState("");
      const [imageUrl, setImageUrl] = useState("");
      const [category, setCategory] = useState("");
      const [description, setDescription] = useState("");
      const [status, setStatus] = useState("");
      const [slug, setSlug] = useState("");
      const [loading, setLoading] = useState(false);

      const [seoTitle, setSeoTitle] = useState(null);
      const [seoKeyword, setSeoKeyword] = useState(null);
      const [seoDescription, setSeoDescription] = useState(null);

      const handleFormSubmit = async (event) => {
            event.preventDefault();
            setLoading(true);
            const data = {
                  slug: slug,
                  title: title,
                  summary: summary,
                  description: description,
                  category: category,
                  image: image,
                  status: status,
                  seo_title: seoTitle,
                  seo_keyword: seoKeyword,
                  seo_description: seoDescription,
            };

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/blogs`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem("token")
                                    )}`,
                                    "Content-Type": "multipart/form-data",
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              setImageUrl("");
                              $("form").trigger("reset");
                              toast.success(response.data.message);
                        }

                        if (response.data.result === "error") {
                              if (response.data.message.title) {
                                    toast.error(response.data.message.title);
                              }
                              if (response.data.message.image) {
                                    toast.error(response.data.message.image);
                              }
                              if (response.data.message.summary) {
                                    toast.error(response.data.message.summary);
                              }
                              if (response.data.message.description) {
                                    toast.error(
                                          response.data.message.description
                                    );
                              }
                              if (response.data.message.category) {
                                    toast.error(response.data.message.category);
                              }
                              if (response.data.message.status) {
                                    toast.error(response.data.message.status);
                              }
                              if (response.data.message.slug) {
                                    toast.error(response.data.message.slug);
                              }
                              if (response.data.message.seo_description) {
                                    toast.error(
                                          response.data.message.seo_description
                                    );
                              }
                              if (response.data.message.seo_title) {
                                    toast.error(
                                          response.data.message.seo_title
                                    );
                              }
                              if (response.data.message.seo_keyword) {
                                    toast.error(
                                          response.data.message.seo_keyword
                                    );
                              }
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      const loadCategories = async () => {
            setIsLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/blogs/categories/list`,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem("token")
                                    )}`,
                                    "Content-Type": "multipart/form-data",
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              setCategories(response.data.categories);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setIsLoading(false);
      };

      useEffect(() => {
            loadCategories();
      }, []);

      return (
            <>
                  <Form onSubmit={handleFormSubmit}>
                        <Row>
                              <Col lg={12}>
                                    <Card>
                                          <CardHeader title="Create Blog">
                                                <LinkButton
                                                      title="Back"
                                                      link="/dashboard/blogs"
                                                />
                                          </CardHeader>
                                          <Card.Body>
                                                <Row className="g-3">
                                                      <FormInput
                                                            title="Title"
                                                            classes={8}
                                                            required={true}
                                                            handleChange={
                                                                  setTitle
                                                            }
                                                      />
                                                      <FormInput
                                                            title="Slug"
                                                            classes={4}
                                                            required={true}
                                                            handleChange={
                                                                  setSlug
                                                            }
                                                      />
                                                      <FormSelect
                                                            title="Category"
                                                            classes={4}
                                                            options={categories}
                                                            loading={isLoading}
                                                            required={true}
                                                            setData={
                                                                  setCategory
                                                            }
                                                      />

                                                      <FormStatusSelect
                                                            title="Status"
                                                            classes={4}
                                                            setStatus={
                                                                  setStatus
                                                            }
                                                      />
                                                      <FormTextarea
                                                            title="Summary"
                                                            required={true}
                                                            handleChange={
                                                                  setSummary
                                                            }
                                                      />
                                                      <FormDescription
                                                            title="Description"
                                                            required={true}
                                                            setDescription={
                                                                  setDescription
                                                            }
                                                      />
                                                </Row>
                                          </Card.Body>
                                    </Card>

                                    <Card className="mt-4">
                                          <Card.Body>
                                                <Row>
                                                      <FormImage
                                                            title="Featured Image"
                                                            setImage={setImage}
                                                            setImageUrl={
                                                                  setImageUrl
                                                            }
                                                            required={true}
                                                            imageUrl={imageUrl}
                                                      />
                                                </Row>
                                          </Card.Body>
                                    </Card>

                                    <FormSeo
                                          setSeoDescription={setSeoDescription}
                                          setSeoTitle={setSeoTitle}
                                          setSeoKeyword={setSeoKeyword}
                                    />

                                    <Card className="mt-4 mb-5">
                                          <Card.Body>
                                                <Col lg={12}>
                                                      <FormButton
                                                            title="Submit"
                                                            loading={loading}
                                                      />
                                                </Col>
                                          </Card.Body>
                                    </Card>
                              </Col>
                        </Row>
                  </Form>
            </>
      );
};

export default BlogCreate;
