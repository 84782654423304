import React from "react";
import CheckMultiplePermission from "../../roles/CheckMultiplePermission";
import { MenuSeparator } from "../../navigation/styles";
import MenuItem from "../../metis/MenuItem";
import { NavLink } from "react-router-dom";
import UserIcon from "../../dashboard/UserIcon";
import MenuLink from "../../metis/MenuLink";

const UserManage = ({ userPermissions }) => {
      return (
            <>
                  <CheckMultiplePermission
                        userPermissions={userPermissions}
                        permissions={[
                              "user-list",
                              "user-create",
                              "user-edit",
                              "user-show",
                              "role-list",
                              "role-create",
                              "role-edit",
                              "role-delete",
                              "seller-list",
                              "seller-create",
                              "seller-show",
                              "seller-edit",
                              "seller-delete",
                              "seller-request-list",
                              "seller-request-delete",
                              "customer-list",
                              "customer-create",
                              "customer-edit",
                              "customer-delete",
                              "customer-request-list",
                              "customer-request-edit",
                              "customer-request-create",
                              "customer-request-show",
                        ]}
                  >
                        <MenuSeparator>User Manages</MenuSeparator>

                        <CheckMultiplePermission
                              userPermissions={userPermissions}
                              permissions={[
                                    "user-list",
                                    "user-create",
                                    "user-edit",
                                    "user-show",
                                    "role-list",
                                    "role-create",
                                    "role-edit",
                                    "role-delete",
                              ]}
                        >
                              <MenuItem
                                    title="User Manage"
                                    icon={<UserIcon />}
                                    links={[
                                          "/dashboard/users",
                                          "/dashboard/users/create",
                                          "/dashboard/users/:userId/edit",
                                          "/dashboard/users/:userId/show",
                                    ]}
                              >
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                "user-list",
                                                "user-create",
                                                "user-edit",
                                                "user-delete",
                                          ]}
                                    >
                                          <li>
                                                <NavLink to="/dashboard/users">
                                                      All User
                                                </NavLink>
                                          </li>
                                    </CheckMultiplePermission>
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                "role-list",
                                                "role-create",
                                                "role-edit",
                                                "role-delete",
                                          ]}
                                    >
                                          <li>
                                                <NavLink to="/dashboard/roles">
                                                      Role & Permission
                                                </NavLink>
                                          </li>
                                    </CheckMultiplePermission>
                              </MenuItem>
                        </CheckMultiplePermission>

                        <CheckMultiplePermission
                              userPermissions={userPermissions}
                              permissions={[
                                    "customer-list",
                                    "customer-create",
                                    "customer-edit",
                                    "customer-delete",
                                    "customer-request-list",
                                    "customer-request-edit",
                                    "customer-request-create",
                                    "customer-request-show",
                              ]}
                        >
                              <MenuItem
                                    title="Customer Manage"
                                    icon={<UserIcon />}
                                    links={[
                                          "/dashboard/customers",
                                          "/dashboard/customers/create",
                                          "/dashboard/customers/:customerId/edit",
                                          "/dashboard/customers/:customerId/show",
                                    ]}
                              >
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                "customer-list",
                                                "customer-create",
                                                "customer-edit",
                                                "customer-delete",
                                          ]}
                                    >
                                          <MenuLink
                                                link="/dashboard/customers"
                                                title="All Customers"
                                                links={[
                                                      "/dashboard/customers",
                                                      "/dashboard/customers/create",
                                                      "/dashboard/customers/:customerId/edit",
                                                      "/dashboard/customers/:customerId/show",
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                "customer-request-list",
                                                "customer-request-edit",
                                                "customer-request-create",
                                                "customer-request-show",
                                          ]}
                                    >
                                          <li>
                                                <NavLink to="/dashboard/customer_requests">
                                                      Customer Request
                                                </NavLink>
                                          </li>
                                    </CheckMultiplePermission>
                              </MenuItem>
                        </CheckMultiplePermission>

                        <CheckMultiplePermission
                              userPermissions={userPermissions}
                              permissions={[
                                    "seller-list",
                                    "seller-create",
                                    "seller-edit",
                                    "seller-delete",
                                    "seller-request-list",
                                    "seller-request-show",
                              ]}
                        >
                              <MenuItem
                                    title="Seller Manage"
                                    icon={<UserIcon />}
                                    links={[
                                          "/dashboard/sellers",
                                          "/dashboard/sellers/create",
                                          "/dashboard/sellers/:sellerId/edit",
                                          "/dashboard/sellers/:sellerId/show",
                                          "/dashboard/seller_requests",
                                          "/dashboard/seller_requests/:sellerId/show",
                                    ]}
                              >
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                "seller-list",
                                                "seller-create",
                                                "seller-edit",
                                                "seller-delete",
                                          ]}
                                    >
                                          <MenuLink
                                                link="/dashboard/sellers"
                                                title="All Seller"
                                                links={[
                                                      "/dashboard/sellers",
                                                      "/dashboard/sellers/create",
                                                      "/dashboard/sellers/:sellerId/edit",
                                                      "/dashboard/sellers/:sellerId/show",
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                                    <CheckMultiplePermission
                                          userPermissions={userPermissions}
                                          permissions={[
                                                "seller-request-list",
                                                "seller-request-delete",
                                          ]}
                                    >
                                          <MenuLink
                                                link="/dashboard/seller_requests"
                                                title="Seller Request"
                                                links={[
                                                      "/dashboard/seller_requests",
                                                      "/dashboard/seller_requests/:sellerId/show",
                                                ]}
                                          />
                                    </CheckMultiplePermission>
                              </MenuItem>
                        </CheckMultiplePermission>
                  </CheckMultiplePermission>
            </>
      );
};

export default UserManage;
