import React from "react";
import { Route, Routes } from "react-router-dom";

import Dashboard from "../backend/dashboard";

// Category

import {
      BannerList,
      BannerCreate,
      BannerEdit,
      BannerShow,
} from "../backend/banner";
import {
      TestimonialList,
      TestimonialCreate,
      TestimonialShow,
      TestimonialEdit,
} from "../backend/testimonials";
import { PageList, PageCreate, PageEdit, PageShow } from "../backend/pages";
import { RoleCreate, RoleEdit, RoleList } from "../backend/roles";
import {
      BlogCategoryList,
      BlogCategoryCreate,
      BlogCategoryEdit,
      BlogCategoryShow,
} from "../backend/blogCategory";
import { BlogCreate, BlogEdit, BlogShow, BlogTable } from "../backend/blog";
import {
      FaqTypeCreate,
      FaqTypeEdit,
      FaqTypeList,
      FaqTypeShow,
} from "../backend/faqType";
import { FaqCreate, FaqEdit, FaqList, FaqShow } from "../backend/faqs";
import {
      FaqRequestEdit,
      FaqRequestList,
      FaqRequestShow,
} from "../backend/faqRequest";
import {
      PluginCreate,
      PluginEdit,
      PluginList,
      PluginShow,
} from "../backend/plugins";
import { JobCreate, JobEdit, JobList, JobShow } from "../backend/jobs";
import { CandidateList, CandidateShow } from "../backend/candidates";

import {
      SubscriberCreate,
      SubscriberEdit,
      SubscriberList,
} from "../backend/subscribers";
import {
      ContactRequestList,
      ContactRequestShow,
} from "../backend/contactRequest";
import { UserCreate, UserEdit, UserList, UserShow } from "../backend/users";
import PageNotFound from "../components/PageNotFound";

const PublicRouter = () => {
      return (
            <>
                  <Routes>
                        <Route path="/dashboard" element={<Dashboard />} />

                        {/* Faq */}
                        <Route path="/dashboard/faqs" element={<FaqList />} />
                        <Route
                              path="/dashboard/faqs/create"
                              element={<FaqCreate />}
                        />
                        <Route
                              path="/dashboard/faqs/:faqId/edit"
                              element={<FaqEdit />}
                        />
                        <Route
                              path="/dashboard/faqs/:faqId/show"
                              element={<FaqShow />}
                        />

                        {/* Faq Type */}
                        <Route
                              path="/dashboard/faqs/types"
                              element={<FaqTypeList />}
                        />
                        <Route
                              path="/dashboard/faqs/types/create"
                              element={<FaqTypeCreate />}
                        />
                        <Route
                              path="/dashboard/faqs/types/:typeId/edit"
                              element={<FaqTypeEdit />}
                        />
                        <Route
                              path="/dashboard/faqs/types/:typeId/show"
                              element={<FaqTypeShow />}
                        />

                        {/* Faq Request */}
                        <Route
                              path="/dashboard/faqs/requests"
                              element={<FaqRequestList />}
                        />
                        <Route
                              path="/dashboard/faqs/requests/:faqId/show"
                              element={<FaqRequestShow />}
                        />
                        <Route
                              path="/dashboard/faqs/requests/:faqId/edit"
                              element={<FaqRequestEdit />}
                        />

                        {/* Testimonial */}
                        <Route
                              path="/dashboard/testimonials"
                              element={<TestimonialList />}
                        />
                        <Route
                              path="/dashboard/testimonials/create"
                              element={<TestimonialCreate />}
                        />
                        <Route
                              path="/dashboard/testimonials/:testimonialId/edit"
                              element={<TestimonialEdit />}
                        />
                        <Route
                              path="/dashboard/testimonials/:testimonialId/show"
                              element={<TestimonialShow />}
                        />

                        {/* Plugin */}
                        <Route
                              path="/dashboard/plugins"
                              element={<PluginList />}
                        />
                        <Route
                              path="/dashboard/plugins/create"
                              element={<PluginCreate />}
                        />
                        <Route
                              path="/dashboard/plugins/:pluginId/edit"
                              element={<PluginEdit />}
                        />
                        <Route
                              path="/dashboard/plugins/:pluginId/show"
                              element={<PluginShow />}
                        />

                        {/* Job */}
                        <Route path="/dashboard/jobs" element={<JobList />} />
                        <Route
                              path="/dashboard/jobs/create"
                              element={<JobCreate />}
                        />
                        <Route
                              path="/dashboard/jobs/:jobId/edit"
                              element={<JobEdit />}
                        />
                        <Route
                              path="/dashboard/jobs/:jobId/show"
                              element={<JobShow />}
                        />

                        {/* Candidate */}
                        <Route
                              path="/dashboard/candidates"
                              element={<CandidateList />}
                        />
                        <Route
                              path="/dashboard/candidates/:candidateId/show"
                              element={<CandidateShow />}
                        />

                        {/* Subscriber */}
                        <Route
                              path="/dashboard/subscribers"
                              element={<SubscriberList />}
                        />
                        <Route
                              path="/dashboard/subscribers/create"
                              element={<SubscriberCreate />}
                        />
                        <Route
                              path="/dashboard/subscribers/:subscriberId/edit"
                              element={<SubscriberEdit />}
                        />

                        {/* Contact Request */}
                        <Route
                              path="/dashboard/contact/requests"
                              element={<ContactRequestList />}
                        />
                        <Route
                              path="/dashboard/contact/requests/:contactId/show"
                              element={<ContactRequestShow />}
                        />

                        {/* Banner */}
                        <Route
                              path="/dashboard/banners"
                              element={<BannerList />}
                        />
                        <Route
                              path="/dashboard/banners/create"
                              element={<BannerCreate />}
                        />
                        <Route
                              path="/dashboard/banners/:bannerId/edit"
                              element={<BannerEdit />}
                        />
                        <Route
                              path="/dashboard/banners/:bannerId/show"
                              element={<BannerShow />}
                        />

                        {/* Blog */}
                        <Route
                              path="/dashboard/blogs"
                              element={<BlogTable />}
                        />
                        <Route
                              path="/dashboard/blogs/create"
                              element={<BlogCreate />}
                        />
                        <Route
                              path="/dashboard/blogs/:blogId/edit"
                              element={<BlogEdit />}
                        />
                        <Route
                              path="/dashboard/blogs/:blogId/show"
                              element={<BlogShow />}
                        />

                        {/* Blog Category */}
                        <Route
                              path="/dashboard/blogs/categories/list"
                              element={<BlogCategoryList />}
                        />
                        <Route
                              path="/dashboard/blogs/categories/create"
                              element={<BlogCategoryCreate />}
                        />
                        <Route
                              path="/dashboard/blogs/categories/:blogCategoryId/edit"
                              element={<BlogCategoryEdit />}
                        />
                        <Route
                              path="/dashboard/blogs/categories/:blogCategoryId/show"
                              element={<BlogCategoryShow />}
                        />

                        {/* Page */}
                        <Route path="/dashboard/pages" element={<PageList />} />
                        <Route
                              path="/dashboard/pages/create"
                              element={<PageCreate />}
                        />
                        <Route
                              path="/dashboard/pages/:pageId/edit"
                              element={<PageEdit />}
                        />
                        <Route
                              path="/dashboard/pages/:pageId/show"
                              element={<PageShow />}
                        />

                        {/* Roles */}
                        <Route path="/dashboard/roles" element={<RoleList />} />
                        <Route
                              path="/dashboard/roles/create"
                              element={<RoleCreate />}
                        />
                        <Route
                              path="/dashboard/roles/:roleId/edit"
                              element={<RoleEdit />}
                        />

                        {/* User */}
                        <Route path="/dashboard/users" element={<UserList />} />
                        <Route
                              path="/dashboard/users/create"
                              element={<UserCreate />}
                        />
                        <Route
                              path="/dashboard/users/:userId/edit"
                              element={<UserEdit />}
                        />
                        <Route
                              path="/dashboard/users/:userId/show"
                              element={<UserShow />}
                        />

                        <Route path="*" element={<PageNotFound />} />
                  </Routes>
            </>
      );
};

export default PublicRouter;
