import React, { useEffect, useState } from "react";
import $ from "jquery";
import "metismenu";
import { MetisMenu } from "./styles";
import axios from "axios";
import DashboardManage from "../menus/DashboardManage";
import UserManage from "../menus/UserManage";
import ContentManage from "../menus/ContentManage";
import WebsiteManage from "../menus/WebsiteManage";
import ProductManage from "../menus/ProductManage";
import StockManage from "../menus/StockManage";
import { Navigate } from "react-router-dom";

const NavMenu = () => {
      const [userPermissions, setUserPermissions] = useState([]);
      const token = JSON.parse(localStorage.getItem("token"));
      const [loading, setLoading] = useState(false);
      const [navigate, setNavigate] = useState(false);

      const loadPermission = async () => {
            setLoading(true);
            await axios
                  .get(`${process.env.REACT_APP_SECRET_KEY}/api/auth/profile`, {
                        headers: {
                              Authorization: `Bearer ${token}`,
                        },
                  })
                  .then((response) => {
                        setUserPermissions(response.data.user.permissions);
                  })
                  .catch((error) => {
                        if (error.response.status === 401) {
                              localStorage.removeItem("token");
                              localStorage.removeItem("isLoggedIn");
                              localStorage.removeItem("tokenExpiration");

                              setNavigate(true);
                        }
                        console.log(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadPermission();
      }, []);

      useEffect(() => {
            $("#menu").metisMenu();
      });

      if (navigate) {
            // Perform logout actions here
            return <Navigate to="/login" />;
      }

      return (
            <>
                  {!loading ? (
                        <MetisMenu className="metismenu" id="menu">
                              <DashboardManage
                                    userPermissions={userPermissions}
                              />

                              <UserManage userPermissions={userPermissions} />

                              <ProductManage
                                    userPermissions={userPermissions}
                              />

                              <WebsiteManage
                                    userPermissions={userPermissions}
                              />
                              <ContentManage
                                    userPermissions={userPermissions}
                              />
                              <StockManage userPermissions={userPermissions} />
                        </MetisMenu>
                  ) : (
                        <>Loading</>
                  )}
            </>
      );
};

export default NavMenu;
